<template>
  <b-row>
    <b-col sm="12" xl="12">
      <b-row class="mb-4 mt-4">
        <b-col>
          <ChangePasswordModal ref="ChangePasswordModal"></ChangePasswordModal>
          <div>
            <h1 class="bold-header-text app-header">Profile</h1>
          </div>
          <b-card class="dashboard-card shadow-sm" style="margin-top: 0.8rem">
            <div>
              <b-row class="formrow">
                <b-col lg="4">
                  <b-form-group label="First Name">
                    <b-form-input
                      id="nested-firstName"
                      class="col-sm-10"
                      v-model.trim="employee.firstName"
                      :state="$v.employee.firstName.$dirty ? !$v.employee.firstName.$error : null"
                      placeholder="First Name"
                      @input="$v.employee.firstName.$touch"
                      @keypress="validateInput($event)"
                    ></b-form-input>
                    <div class="text-danger" v-if="!$v.employee.firstName.maxLength">
                      Maximum Characters Allowed 50!
                    </div>
                    <div
                      class="text-danger"
                      v-if="!$v.employee.firstName.required && $v.employee.firstName.$dirty"
                    >
                      This field is required.
                    </div>
                  </b-form-group>
                </b-col>
                <b-col lg="4">
                  <b-form-group label="Last Name">
                    <b-form-input
                      id="nested-lastName"
                      class="col-sm-10"
                      v-model.trim="employee.lastName"
                      :state="$v.employee.lastName.$dirty ? !$v.employee.lastName.$error : null"
                      placeholder="Last Name"
                      @input="$v.employee.lastName.$touch"
                      @keypress="validateInput($event)"
                    ></b-form-input>
                    <div class="text-danger" v-if="!$v.employee.lastName.maxLength">
                      Maximum Characters Allowed 50!
                    </div>
                    <div
                      class="text-danger"
                      v-if="!$v.employee.lastName.required && $v.employee.lastName.$dirty"
                    >
                      This field is required.
                    </div>
                  </b-form-group>
                </b-col>
                <b-col lg="4">
                  <b-form-group label="Email">
                    <b-form-input
                      id="nested-emailaddress"
                      class="col-sm-6"
                      v-model.trim="employee.email"
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="formrow">
                <b-col lg="4">
                  <div class="">
                    <a class="loginlinks" @click="goToChangePassword">Change Password</a>
                  </div>
                </b-col>
              </b-row>
              <b-row align-h="end" align-content="end">
                <b-col class="mt-2" xs="12" sm="6" md="6" lg="6" xl="4" align-self="end">
                  <div class="data-block application-button-block">
                    <button class="danger-button" @click="cancelForm()">
                      {{ $t("forms.cancel") }}
                    </button>
                  </div>
                </b-col>
                <b-col class="mt-2" xs="12" sm="6" md="6" lg="6" xl="4" align-self="end">
                  <div class="data-block application-button-block">
                    <api-button-overlay :processing="processing">
                      <button @click="onValidateFormSubmit" class="base-button">
                        {{ $t("forms.save") }}
                      </button>
                    </api-button-overlay>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>
<style lang="scss" scoped>
.application-button-block {
  display: flex;
  justify-content: center;
}

.data-block {
  @media (min-width: 600px) {
    flex-basis: 23%;
  }
  @media (max-width: 599px) {
    flex-basis: 100%;
  }
}
</style>
<script>
import AuthorizationMixin from "../../mixins/AuthorizationMixin.vue";
import DirtyDataMixin from "../../mixins/DirtyDataMixin.vue";
import { validationMixin } from "vuelidate";
import ChangePasswordModal from "../../components/Modals/ChangePasswordModal.vue";

const { required, maxLength } = require("vuelidate/lib/validators");

export default {
  components: {
    ChangePasswordModal,
  },
  async created() {
    await this.getCurrentUser();
    this.initDirtyDataWatcher("employee");
  },
  data() {
    return {
      employee: {
        firstName: "",
        lastName: "",
      },
    };
  },
  mixins: [AuthorizationMixin, validationMixin, DirtyDataMixin],
  validations: {
    employee: {
      firstName: {
        maxLength: maxLength(50),
        required,
      },
      lastName: {
        maxLength: maxLength(50),
        required,
      },
    },
  },
  methods: {
    cancelForm() {
      this.$router.push({ name: "Dashboard" });
    },
    async onValidateFormSubmit() {
      this.$v.employee.$touch();
      if (!this.$v.employee.$error) {
        await this.updateCurrentUser(this.employee);
        if (this.statusCode && this.statusCode === 200) {
          this.setDirtyData(false);
        }
      } else {
        this.setDangerMessage("Form must be filled out properly.");
      }
    },
    goToChangePassword() {
      this.$refs.ChangePasswordModal.show({});
    },
    validateInput(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
  },
};
</script>
