<template>
  <b-modal
    id="modal-center"
    centered
    :title="$t(title)"
    v-model="isVisible"
    :no-close-on-backdrop="true"
    @ok="cancel()"
    @cancel="cancel()"
    @hide="cancel()"
  >
    <template>
      <forgot-password type="change-password" />
    </template>
  </b-modal>
</template>
<style>
#modal-center___BV_modal_footer_ {
  display: none;
}
</style>
<script>
import ForgotPassword from "../Login/ForgotPassword.vue";
import { mutations } from "../../store/Login";
import { mapMutations } from "vuex";

export default {
  props: ["openCompanyModal"],
  components: {
    "forgot-password": ForgotPassword,
  },
  data() {
    return {
      isVisible: false,
    };
  },
  computed: {
    title() {
      return "header.change-password";
    },
  },
  methods: {
    ...mapMutations(["setModalInfoMessage", "setModalDangerMessage"]),
    show() {
      this.isVisible = true;
    },
    cancel() {
      this.isVisible = false;
      mutations.reset();
      this.setModalDangerMessage("");
      this.setModalInfoMessage("");
    },
  },
};
</script>
